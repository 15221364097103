import React, { useEffect } from 'react'

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  Result,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "../../src/config/config-helper";


import { useTranslation } from 'react-i18next';


import FacetView from './FacetView';
import FacetLanguageView from './FacetLanguageView';
import ResultView from "./ResultView";

const CustomLayout = (props) => {

  const { setSearchTerm, searchTerm, wasSearched, results, facets, isLoading, trackClickThrough } = props.parameters;

  const { t, i18n } = useTranslation();

  // console.log("pathname", window.location.pathname);
  const pathName = window.location.pathname

  const { translation, translationWithoutCountry} = getConfig();

  const language = translation[pathName?.split('/')[2]+'_'+pathName?.split('/')[1]] || translationWithoutCountry[pathName?.split('/')[2]];


  useEffect(() => {
    i18n.changeLanguage(language || "en");
  }, [])

  // const langdata = JSON.parse(facets)
  console.log("facets?.language[0]?.data?.length", facets?.language && facets?.language[0]?.data?.length);

  const langList = facets?.language && facets?.language[0]?.data?.length


  return (
    <div>
      <Layout

        // header={<SearchBox autocompleteSuggestions={true} />}
        sideContent={
          <div>

            <div className="ss-searchbox-container">
              <SearchBox className='searchBox' autocompleteSuggestions={true} inputProps={{placeholder: t("search")}}
              />

              <div className="ss-search-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path id="Path_426" data-name="Path 426" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)" fill="#34383c" />
                </svg>
              </div>

              <div className="ss-clear-svg" onClick={() => setSearchTerm('')}>
                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path id="Path_362" data-name="Path 362" d="M0,0H24V24H0Z" fill="none" />
                  <path id="Path_363" data-name="Path 363" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#3e318f" />
                </svg>
              </div>

              {/* <button onClick={()=>{clearFilters()}}>Clear</button> */}
            </div>

            {
              results?.length === 0 && <p className='no-result-msg no-result-msg-left'>{t("noResultMsg")}</p>
            }

            <div className="ss-facet-vl-container">

              <div className="ss-facet-container">
                {getFacetFields().map(field => {
                  return (
                    <div key={field}>

                    {
                      !(['language',"country"].includes(field)) &&
                      <Facet key={field} field={field} label={field}

                        view={(props) => {
                          return (
                            <FacetView
                              facets={props}
                            />
                          )

                        }}
                      />
                    }
                    

                      {/* {
                        field === 'language' ?

                          <Facet key={field} field={field} label={field} show={1000}
                            view={(props) => {

                              return (
                                <FacetLanguageView
                                  facets={props}
                                />
                              )

                            }}
                          />

                          :
                          <Facet key={field} field={field} label={field}

                            view={(props) => {
                              return (
                                <FacetView
                                  facets={props}
                                />
                              )

                            }}
                          />
                      } */}




                    </div>
                  )

                })}
              </div>
              <div className="ss-vertical-line"></div>
            </div>
          </div>
        }
        bodyContent={
          <div>
            {
              results?.length === 0 ?
                <p className='no-result-msg no-result-msg-right'>{t("noResultMsg")}</p>
                :
                <div>
                  <div className="ss-search-term-container">
                    <h3 className="ss-search-term">{t("results")}: {searchTerm}</h3>
                  </div>

                  <div className="ss-top-pagination-container">

                    {wasSearched &&
                      <PagingInfo
                        view={(props) => {
                          console.log("page info prop", props);
                          return (
                            <p className="ss-total-results">{props.totalResults} {t("results")}</p>
                          )
                        }}
                      />}

                    <Paging showLessItems={true} className='ss-pagination dsk-pagination' />

                  </div>


                  {wasSearched && (
                    <Sorting
                      style={{ marginBottom: "30px" }}
                      label={t('sortBy')}
                      sortOptions={buildSortOptionsFromConfig(t)}
                    // sortOptions={[
                    //           { name: "title", value: "title", direction: "desc" }
                    // ]}
                    // view={(props)=>{
                    //   console.log("sorting",props)
                    //   return null
                    // }}
                    />
                  )}

                  <div className="tblt-pagination-container">
                    <Paging showLessItems={true} className='ss-pagination tblt-pagination' />
                  </div>


                  {
                    results.map(result => {
                      return(
                      <Results
                        key={result.id.raw}
                        titleField={getConfig().titleField}
                        urlField={getConfig().urlField}
                        thumbnailField={getConfig().thumbnailField}
                        // clickThroughTags={["CallDashboard"]}
                        shouldTrackClickThrough={true}
                        view={(props) => {
                          return (

                            <ResultView
                              result={result}
                              trackClickThrough={trackClickThrough}
                            />

                          )
                        }}
                      />)
                    })
                  }


                </div>
            }



            {/* <Result
    titleField={getConfig().titleField}
    urlField={getConfig().urlField}
    thumbnailField={getConfig().thumbnailField}
    shouldTrackClickThrough={true}
    view={ResultView(results)}
  /> */}
          </div>




        }
        // bodyHeader={
        //   <React.Fragment>
        //     {wasSearched && <PagingInfo />}
        //     {wasSearched && <ResultsPerPage />}
        //   </React.Fragment>
        // }
        bodyFooter={<Paging showLessItems={true} className='ss-pagination' />}
      />
    </div>
  )
}

export default CustomLayout