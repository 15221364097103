import React from 'react';
import { useTranslation } from 'react-i18next';


const FacetView = (props) => {
  
  const { t } = useTranslation();
  
  console.log("facet props after*********************************************",props);

    let label = props.facets.label
    if(props.facets.label === 'content_type'){
      label = 'format'
    }
    // else if (props.facets.label === 'meta_keywords'){
    //   label = 'Tags'
    // }

    
    
    return(
        <div>
          <h3 className="ss-filter-heading">{t(label)}</h3>
          <div className="ss-all-filter">

          {
            props.facets.options.map(option=>{
              
              return <div key={option.value} className='ss-filter-container'>
              <input type='checkbox' 
                  checked={option.selected}
                  className="ss-filter-input"
                    onChange={(e)=>{
                      e.target.checked ? props.facets.onSelect(option.value) : props.facets.onRemove(option.value)
                    }}
                />
    
              <p className="ss-filter-text">
                {option.value} ({option.count})
              </p>
              </div>
            })
          }
          {props.facets.showMore && <div className="ss-facet-more" onClick={props.facets.onMoreClick}>+ More</div>}
          </div>
          
          <hr className="ss-hr-line"></hr>
        </div>
      )
}

export default FacetView