import React from 'react'
import no_thumbnail from '../no_thumbnail.png'

const ResultView = (result) => {
    console.log("result props",result)
    return (
        <div className="ss-result-container"
            onClick={()=>{
                result.trackClickThrough(result.result.id.raw,['resultClick'])
                window.open(`${result.result.url?.raw}`,'_blank')
                }}
        >
            <div >
                <img
                    src={result.result.thumbnail ? result.result.thumbnail?.raw : no_thumbnail}
                    className='ss-result-thumbnail'
                    alt='thumbnail'
                />
            </div>
            <div className='ss-result-body'>
                <h5 className='ss-result-type'>
                {result.result.content_type?.raw}
                </h5>
                <h3 className='ss-result-title'>{result.result.title?.raw}</h3>
                
                <p className='ss-result-tag'>
                {result.result.tags?.raw.join(', ')}
                </p>
            </div>

        </div>
    )
}

export default ResultView