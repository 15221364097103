import React, { useEffect, useState } from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  Result,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styleSheets/style.css'
import FacetView from "./components/FacetView";
import ResultView from "./components/ResultView";
import Loader from "./components/Loader";
import CustomLayout from "./components/CustomLayout";

import './i18Next/I18Next';

const appPathName = window.location.pathname;
console.log("app path name", appPathName?.split('/')[2])

const { hostIdentifier, searchKey, endpointBase, engineName, metaEngines, metaEnginesWithoutCountry, externalSiteCountryLang } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,

  beforeSearchCall: (existingSearchOptions, next) => {
    console.log("existingSearchOptions", existingSearchOptions, next)
    return next({
      ...existingSearchOptions,
      analytics: {
        tags: ["Initial_search"]
      }
    })
  }
});

const metaEngineName = metaEngines[!!appPathName?.split('/')[2] && `${appPathName?.split('/')[2]}-${appPathName?.split('/')[1]}`] ||
  metaEnginesWithoutCountry[appPathName?.split('/')[2]]
// 'public-site';


const searchFilters = [
  { field: "_engine", values: [metaEngineName] },
  ...(!!appPathName?.split('/')[1] ?
    [{
      field: "country",
      values: [appPathName?.split('/')[1], externalSiteCountryLang]
    }]
    :
    []
  ),
  ...(!!appPathName?.split('/')[2] ?
    [{
      field: "language",
      values: [appPathName?.split('/')[2], externalSiteCountryLang]
    }]
    :
    []
  ),

  // {
  //   field: "url_path_dir1",
  //   values: [appPathName?.split('/')[1] ] 
  // },
  // {
  //   field: "url_path_dir2",
  //   values: [appPathName?.split('/')[2] ] 
  // }
]
console.log("searchFilters", JSON.stringify(searchFilters));
const config = {
  searchQuery: {
    filters: searchFilters,
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true

  // initialState: { filters: [
  //   {field: "language",values: [ appPathName?.split('/')[2] ] }
  // ] }
};



export default function App() {

  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');

  const [showHeaderLoader, setShowHeaderLoader] = useState(true);
  const [showFooterLoader, setShowFooterLoader] = useState(true);

  // const [url1, seturl1] = useState(appPathName?.split('/')[1]);
  // const [url2, seturl2] = useState(appPathName?.split('/')[2]);

  const { headerFooterDomain } = getConfig();

  // if(process.env.REACT_APP_ENV.toLowerCase() === 'dev' || process.env.REACT_APP_ENV.toLowerCase() === 'qa'){
  //   headerFooterDomain = process.env.REACT_APP_headerFooterDomain;
  // }else if (process.env.REACT_APP_ENV.toLowerCase() === 'prod'){
  //   headerFooterDomain = process.env.REACT_APP_headerFooterDomainProd;
  // }

  const getCssLink = async () => {
    var tsTime = new Date(new Date().getTime());

    let cssTagHref = await fetch(getConfig().headerApiUrl + (!!appPathName?.split('/')[2] ? `${appPathName?.split('/')[2]}-${appPathName?.split('/')[1]}` : '') + '&ts=' + tsTime)
      .then(response => response.text())
      .then(data => {
        const enc1 = data;
        const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

        // var imgs = newDocument.getElementsByTagName("img");


        // for(let i=0; i< imgs.length; i++){
        //   if(imgs[i].src.indexOf("http://") === 0 || imgs[i].src.indexOf("https://") === 0){
        //     let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');
        //     oldHeaderLogoSRC.shift();
        //     imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');
        //   }
        // }

        // const converted = newDocument.body.innerHTML;

        // setHeader(converted);

        let staticLinks = []
        let links = newDocument.getElementsByTagName("link");
        console.log("link", links)
        for (let i = 0; i < links.length; i++) {
          if (links[i].href.indexOf(process.env.REACT_APP_ScriptKey) !== -1) {
            staticLinks.push(links[i].href)
          }
        }

        // let scripts = newDocument.getElementsByTagName("script");
        // console.log("scripts",scripts);
        // for(let i=0; i<scripts.length; i++){
        //   if(scripts[i].src.indexOf(process.env.REACT_APP_ScriptKey) !== -1){
        //     staticLinks.push(scripts[i].src)
        //   }
        // }

        // staticLinks.push(converted)
        return staticLinks

      }).catch((error) => {
        console.log("error occurred while fetching link");
        return ''
      });
    return cssTagHref
  }



  const getHeader = async () => {
    var tsTime = new Date(new Date().getTime());
    setShowHeaderLoader(true);

    let jsTagSrc = await fetch(getConfig().headerApiUrl + (!!appPathName?.split('/')[2] ? `${appPathName?.split('/')[2]}-${appPathName?.split('/')[1]}` : '') + '&ts=' + tsTime)
      .then(response => response.text())
      .then(data => {
        const enc1 = data;
        const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

        var imgs = newDocument.getElementsByTagName("img");
        var sources = newDocument.getElementsByTagName("source");
        console.log("sources______", sources)

        for (let i = 0; i < sources.length; i++) {
          if (sources[i].srcset.indexOf(".svg") != -1) {
            sources[i].srcset = headerFooterDomain + sources[i].srcset;
          }
        }


        for (let i = 0; i < imgs.length; i++) {
          // if(imgs[i].src.indexOf("http://") === 0 || imgs[i].src.indexOf("https://") === 0){
          if (imgs[i].src.indexOf(".svg") != -1) {
            console.log('imgs[i].src', imgs[i].src);
            let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');
            oldHeaderLogoSRC.shift();
            imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');
          }
        }

        const converted = newDocument.body.innerHTML;

        setHeader(converted);

        let staticLinks = []
        // let links = newDocument.getElementsByTagName("link");
        // console.log("link",links)
        // for(let i=0; i<links.length; i++){
        //   if(links[i].href.indexOf(process.env.REACT_APP_ScriptKey) !== -1){
        //     staticLinks.push(links[i].href)
        //   }
        // }

        let scripts = newDocument.getElementsByTagName("script");
        console.log("scripts", scripts);
        for (let i = 0; i < scripts.length; i++) {
          if (scripts[i].src.indexOf(process.env.REACT_APP_ScriptKey) !== -1) {
            staticLinks.push(scripts[i].src)
          }
        }
        setShowHeaderLoader(false);

        // staticLinks.push(converted)
        return staticLinks

      }).catch((error) => {
        console.log("error occurred while fetching header");
        setShowHeaderLoader(false);
        return ''
      });
    // console.log("jsTagSrc",jsTagSrc);
    return jsTagSrc
  }

  const getFooter = async () => {

    setShowFooterLoader(true);
    var tsTime = new Date(new Date().getTime());
    fetch(getConfig().footerApiUrl + (!!appPathName?.split('/')[2] ? `${appPathName?.split('/')[2]}-${appPathName?.split('/')[1]}` : '') + '&ts=' + tsTime)
      .then(response => response.text())
      .then(data => {
        const enc2 = data;
        const newDocument2 = (new DOMParser()).parseFromString(enc2, 'text/html');

        var aTags = newDocument2.body.querySelectorAll('.footer-hyperlink-section a');

        console.log("atags", aTags);

        for (let i = 0; i < aTags.length; i++) {
          if (aTags[i].href) {
            // if(aTags[i].href.indexOf(".pdf") != -1 ){
            let oldFooterLogoHref = aTags[i].href?.split('//')[1].split('/');
            oldFooterLogoHref && oldFooterLogoHref.shift();
            aTags[i].href = headerFooterDomain + oldFooterLogoHref?.join('/');
            // }
          }
        }

        var imgs = newDocument2.getElementsByTagName("img");

        for (let i = 0; i < imgs.length; i++) {
          // if(imgs[i].src.indexOf("http://") === 0 || imgs[i].src.indexOf("https://") === 0){
          if (imgs[i].src.indexOf(".svg") != -1) {
            let oldFooterLogoSRC = imgs[i].src.split('//')[1].split('/');
            oldFooterLogoSRC.shift();
            imgs[i].src = headerFooterDomain + oldFooterLogoSRC.join('/');
          }
        }

        var sources = newDocument2.getElementsByTagName("source");

        for (let i = 0; i < sources.length; i++) {
          if (sources[i].srcset.indexOf(".svg") != -1) {
            sources[i].srcset = headerFooterDomain + sources[i].srcset;
          }
        }



        // const footerLogo = newDocument2.body.querySelector(".footer-logo img");
        // let oldFooterLogoSRC = footerLogo.src.split('//')[1].split('/');
        // oldFooterLogoSRC.shift();

        // footerLogo.src = headerFooterDomain + oldFooterLogoSRC.join('/')

        // const svgs = newDocument2.body.querySelectorAll(".footer-media-section a img");
        // for (let i = 0; i < svgs.length; i++) {
        //   let oldSRC = svgs[i].src.split('//')[1].split('/');
        //   oldSRC.shift();
        //   // svgs[i].src = 'https://extqa.ocdx.com/'+ oldSRC.join('/')
        //   svgs[i].src = headerFooterDomain + oldSRC.join('/')
        // }

        const converted2 = newDocument2.body.innerHTML;
        setFooter(converted2);
        setShowFooterLoader(false);

      }).catch((error) => {
        console.log("error occurred while fetching footer");
        setShowFooterLoader(false);
        return ''
      })

  }

  const scriptLoaded = () => {
    window.init();
    window.setSelectedCountry();
  }

  useEffect(() => {
    const getScriptTag = async () => {

      const [csslink] = await getCssLink();

      const csslinkList = csslink?.split('//')[1].split('/');
      csslinkList && csslinkList.shift()

      console.log("csslinkList", csslinkList)

      const cssLinkTag = document.createElement("link");
      cssLinkTag.setAttribute("rel", "stylesheet")
      cssLinkTag.setAttribute("type", "text/css")
      cssLinkTag.href = headerFooterDomain + csslinkList?.join('/');

      console.log('css', cssLinkTag.href)

      // document.head.appendChild(cssLinkTag);
      document.head.insertBefore(cssLinkTag, document.head.firstChild)

      getFooter();
      const [jsFullSrc] = await getHeader();

      // console.log("check",cssFullSrc, jsFullSrc)


      // const cssFullSrcList = cssFullSrc.split('//')[1].split('/');
      // cssFullSrcList.shift();

      // console.log("cssFullSrcList",cssFullSrcList)

      // const cssLink = document.createElement("link");
      // cssLink.async = true;
      // cssLink.setAttribute("rel", "stylesheet");
      // cssLink.setAttribute("type", "text/css");
      // cssLink.href = 'https://extqa.ocdx.com/' + cssFullSrcList.join('/');

      // console.log('css',cssLink.href);

      // document.head.appendChild(cssLink);




      const jsFullSrcList = jsFullSrc?.split('//')[1].split('/');
      jsFullSrcList && jsFullSrcList.shift()

      console.log("jsFullSrcList", jsFullSrcList)

      const jsScript = document.createElement("script");
      jsScript.src = headerFooterDomain + jsFullSrcList?.join('/');

      console.log('chiev', jsScript.src)

      document.body.appendChild(jsScript);
      jsScript.onload = () => scriptLoaded();

      // setHeader(headerHtml);
    }

    getScriptTag();




    // const script = document.createElement("script");
    // script.async = true;
    // if(process.env.REACT_APP_ENV.toLowerCase() === 'dev' || process.env.REACT_APP_ENV.toLowerCase() === 'qa'){
    //   script.src = "https://extqa.ocdx.com/etc.clientlibs/orthoclinicaldiagnosticswebproperties/clientlibs/clientlib-base.lc-272d659e930dcfd7df3ec3bc36a79cdb-lc.min.js";
    // }else if (process.env.REACT_APP_ENV.toLowerCase() === 'prod'){
    //   script.src = "";
    // }

    // document.body.appendChild(script);
    // script.onload = () =>scriptLoaded();

    // window.onload = ()=>scriptLoaded();

  }, [])

  return (
    <SearchProvider config={config}

    >
      <WithSearch
        mapContextToProps={({ wasSearched, results, searchTerm, setSearchTerm, isLoading, pagingEnd, fliters, clearFilters, facets, trackClickThrough }) => ({ wasSearched, results, searchTerm, setSearchTerm, isLoading, pagingEnd, fliters, clearFilters, facets, trackClickThrough })}>
        {({ wasSearched, results, searchTerm, setSearchTerm, isLoading, pagingEnd, fliters, clearFilters, facets, trackClickThrough }) => {


          console.log("results", results)
          console.log("searchTerm", searchTerm)
          console.log("isLoading", isLoading)
          console.log("fliters", fliters)
          console.log("facets", facets);





          // main 
          return (
            <div className="App">
              <ErrorBoundary>

                {isLoading && <Loader />}
                {
                  metaEngineName ?

                    <>
                      {

                        showHeaderLoader ?
                          <div className="headerPlaceholder">Loading...</div>
                          :
                          <div id="header" className="" dangerouslySetInnerHTML={{ __html: header }}></div>

                      }


                      <Router>
                        <Routes>

                          <Route path="/" element={
                            <CustomLayout
                              parameters={{ setSearchTerm, searchTerm, wasSearched, results, facets, isLoading, trackClickThrough }}
                            />
                          } />

                          <Route exact path="/:country/:language" element={
                            <CustomLayout
                              parameters={{ setSearchTerm, searchTerm, wasSearched, results, facets, isLoading, trackClickThrough }}
                            />
                          } />

                        </Routes>
                      </Router>

                      {
                        showFooterLoader ?
                          <div className="footerPlaceholder">Loading...</div>
                          :
                          <div id="footer" dangerouslySetInnerHTML={{ __html: footer }}></div>

                      }

                    </>
                    :
                    <div className="error-msg">The country and language is not specified or incorrect</div>
                }                   


              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
